import Site from 'shared/utils/site'

export const siteName = Site.isKaigo()
    ? '介護'
    : Site.isHoiku()
    ? '保育'
    : Site.isKango()
    ? '看護'
    : Site.isPos()
    ? 'POS・治療'
    : Site.isGishi()
    ? '技師'
    : Site.isEiyoushi()
    ? '栄養士'
    : Site.isDental()
    ? 'デンタル'
    : null
