import site from 'shared/utils/site'

// 求人特徴の絞り込みの保有資格のマッピング

const hoikuOfferQualificationMappings = {
    保育教諭: ['幼稚園教諭免許（専修・１種・２種）'],
    その他: [
        '薬剤師',
        '保健師',
        '助産師',
        'その他の医療技術関係資格',
        'その他の医療・保健衛生関係資格',
        '手話通訳士',
        'その他の福祉・介護関係資格',
        '公認会計士',
        '税理士',
        '社会保険労務士',
        '３級ファイナンシャル・プランニング技能士',
        '小学校教諭免許（専修・１種・２種）',
        '中学校教諭免許（専修・１種・２種）',
        '高等学校教諭免許（専修・１種）',
        '養護教諭免許（専修・１種・２種）',
        '特別支援学校教諭免許（専修・１種・２種）',
        '社会教育主事',
        'その他の専修・各種学校教員関係資格',
        'その他のスポーツ等指導関係資格',
        '実用英語技能検定２級',
        'ＴＯＥＩＣ（６００点～）',
        '秘書検定２級',
        '秘書検定３級',
        '日商簿記２級',
        '日商簿記３級',
        '簿記能力検定（全経２級）',
        '簿記能力検定（全経３級）',
        '簿記実務検定２級',
        '簿記実務検定３級',
        'その他の会計事務関係資格',
        'パソコンワープロ・表計算可',
        'パソコンワープロ可',
        'その他の調理等関係資格',
        '交通誘導警備業務検定２級',
        'その他の保安関係資格',
        '大型自動車免許',
        '大型自動車第二種免許',
        '普通自動車第二種免許',
        '原動機付自転車免許',
        '中型自動車免許',
        '中型自動車第二種免許',
        '８トン限定中型自動車免許',
        '準中型自動車免許',
        'その他の免許資格',
    ],
}
const kaigoOfferQualificationMappings = {
    正看護師: ['看護師'],
    ヘルパー１級: ['ホームヘルパー１級', '介護職員実務者研修修了者'],
    ヘルパー２級: [
        'ホームヘルパー２級',
        'ホームヘルパー３級',
        '介護職員初任者研修修了者',
    ],
    '精神保健福祉士（PSW）': ['精神保健福祉士'],
    介護支援専門員: ['介護支援専門員（ケアマネージャー）'],
    '医療ソーシャルワーカー（MSW）': [
        '医療ソーシャルワーカー（MSW）\u200B', //NOTE: ZeroWidthSpaceが入っているデータがほとんどだったのでそっちもヒットするように
    ],
    事務: [
        '医療保険請求事務者（医科・歯科）',
        '医療事務実務士１級',
        '医療事務実務士２級',
        'メディカルクラーク（医療事務技能審査）',
        'メディカルクラーク（医療事務技能審査）',
        '診療報酬請求事務能力認定試験（医科・歯科）',
        '医療事務検定試験',
        '診療情報管理士',
        '医療事務資格',
        '医事管理士',
        'ケアクラーク技能認定',
        '介護事務管理士',
        '介護保険事務管理士',
        '調剤事務管理士',
    ],
    その他: [
        '食品冷凍技士',
        '食品衛生責任者',
        'その他の食品技術関係資格',
        'ボイラー・タービン主任技術者',
        '一級建築士',
        '二級建築士',
        '２級建築施工管理技士',
        '１級土木施工管理技士',
        '２級土木施工管理技士',
        '応用情報技術者',
        '基本情報技術者',
        'ＩＴコーディネータ',
        'ＩＴパスポート',
        'Ｌｉｎｕｘ技術者認定資格',
        '情報処理安全確保支援士',
        'その他の情報処理技術関係資格',
        '薬剤師',
        '保健師',
        '助産師',
        'その他の医療技術関係資格',
        '第一種衛生管理者',
        '第二種衛生管理者',
        '認知症ケア専門士',
        'その他の医療・保健衛生関係資格',
        '手話通訳士',
        '福祉住環境コーディネーター検定',
        '福祉用具専門相談員',
        'その他の福祉・介護関係資格',
        '公認会計士',
        'ファイナンシャルプランナー（ＣＦＰ／ＡＦＰ）',
        '社会保険労務士',
        '小学校教諭免許（専修・１種・２種）',
        '中学校教諭免許（専修・１種・２種）',
        '高等学校教諭免許（専修・１種）',
        '養護教諭免許（専修・１種・２種）',
        '特別支援学校教諭免許（専修・１種・２種）',
        '指定自動車教習所指導員',
        '日本語教師養成講座修了（４２０時間）',
        'その他の専修・各種学校教員関係資格',
        'スポーツプログラマー',
        'フィットネストレーナー',
        '健康運動指導士',
        '健康運動実践指導者',
        '２級キャリアコンサルティング技能士',
        '産業カウンセラー',
        'キャリアコンサルタント',
        'その他のカウンセリング関係資格',
        '宅地建物取引士（旧：宅地建物取引主任者）',
        '秘書検定３級',
        '日商簿記１級',
        '日商簿記２級',
        '日商簿記３級',
        '簿記能力検定（全経１級）',
        '簿記能力検定（全経２級）',
        '簿記能力検定（全経３級）',
        '簿記実務検定１級',
        '簿記実務検定２級',
        '簿記実務検定３級',
        'その他の会計事務関係資格',
        '行政書士',
        '運行管理者（旅客）',
        'その他の専門的事務関係資格',
        'ＭＯＳ Ｅｘｃｅｌエキスパート',
        'ＭＯＳ Ｅｘｃｅｌスペシャリスト',
        'ＭＯＳ Ｗｏｒｄエキスパート',
        'ＭＯＳ Ｗｏｒｄスペシャリスト',
        'パソコンワープロ・表計算可',
        'ＭＯＳ ＰｏｗｅｒＰｏｉｎｔ',
        'その他のＯＡ機器操作関係資格',
        'ビジキャリ（人事・人材開発・労務管理）',
        '理容師',
        '美容師',
        'トリマー',
        '葬祭ディレクター１級',
        '葬祭ディレクター２級',
        'フードコーディネーター',
        'その他のサービス関係資格',
        '専門調理師・調理技能士',
        'その他の調理等関係資格',
        '防火管理者（甲種・乙種）',
        '雑踏警備業務検定２級',
        '交通誘導警備業務検定２級',
        '大型自動車免許',
        '大型自動車第二種免許',
        '普通自動車第二種免許',
        '大型特殊自動車免許',
        '自動二輪車免許',
        '原動機付自転車免許',
        'フォークリフト運転技能者',
        '中型自動車免許',
        '中型自動車第二種免許',
        '８トン限定中型自動車免許',
        '準中型自動車免許',
        '５トン限定準中型自動車免許',
        '危険物取扱者（乙種）',
        '三級自動車整備士',
        '製菓衛生師',
        'その他の食料品製造関係資格',
        '車両系建設機械（解体用）運転技能者',
        '第一種電気工事士',
        '第二種電気工事士',
        'その他の電気工事関係資格',
        'その他の免許資格',
    ],
}

const kangoOfferQualificationMappings = {
    正看護師: ['看護師'],
    医師: ['歯科医師'],
    '公認心理士/臨床心理士': ['臨床心理士', '公認心理士'],
    '医療ソーシャルワーカー（MSW）': [
        '社会福祉士',
        '医療ソーシャルワーカー（MSW）\u200B', //NOTE: ZeroWidthSpaceが入っているデータがほとんどだったのでそっちもヒットするように
    ],
    事務: [
        '医療保険請求事務者（医科・歯科）',
        '医療事務実務士１級',
        '医療事務実務士２級',
        'メディカルクラーク（医療事務技能審査）',
        'メディカルクラーク（医療事務技能審査）',
        '診療報酬請求事務能力認定試験（医科・歯科）',
        '医療事務検定試験',
        '診療情報管理士',
        '医療事務資格',
        '医事管理士',
        'ドクターズクラーク（医師事務作業補助技能認定）',
        '医事コンピュータ技能検定準１級',
        '医事コンピュータ技能検定２級',
        '医事コンピュータ技能検定３級',
        '医療事務管理士（医科・歯科）',
        'その他のコンピュータサービス技能評価試験',
    ],
    その他: [
        '食品衛生責任者',
        'その他の食品技術関係資格',
        'ボイラー・タービン主任技術者',
        '第三種電気主任技術者',
        '２級電気工事施工管理技士',
        '二級建築士',
        '建築物環境衛生管理技術者',
        '２級建築施工管理技士',
        '基本情報技術者',
        'ＩＴパスポート',
        'その他の情報処理技術関係資格',
        '衛生検査技師',
        'その他の医療技術関係資格',
        '救急救命士',
        'その他の医療・保健衛生関係資格',
        '福祉住環境コーディネーター検定',
        '福祉用具専門相談員',
        'その他の福祉・介護関係資格',
        '社会保険労務士',
        '小学校教諭免許（専修・１種・２種）',
        '中学校教諭免許（専修・１種・２種）',
        '高等学校教諭免許（専修・１種）',
        '特別支援学校教諭免許（専修・１種・２種）',
        '健康運動指導士',
        '健康運動実践指導者',
        'その他の美術・音楽等関係資格',
        'キャリアコンサルタント',
        '秘書検定２級',
        '秘書検定３級',
        '医療秘書技能検定１級',
        '医療秘書技能検定２級',
        '医療秘書技能検定３級',
        'ビジネス文章検定３級',
        '珠算能力検定１級',
        '日商簿記１級',
        '日商簿記２級',
        '日商簿記３級',
        '簿記能力検定（全経３級）',
        '簿記実務検定２級',
        '簿記実務検定３級',
        'その他の会計事務関係資格',
        '医療秘書士',
        'その他の専門的事務関係資格',
        'その他のＯＡ機器操作関係資格',
        'トリマー',
        'その他のサービス関係資格',
        'ソムリエ',
        'その他の調理等関係資格',
        '防火管理者（甲種・乙種）',
        '大型自動車免許',
        '大型自動車第二種免許',
        '普通自動車第二種免許',
        '原動機付自転車免許',
        '中型自動車免許',
        '８トン限定中型自動車免許',
        '危険物取扱者（乙種）',
        '危険物取扱者（丙種）',
        '１級ボイラー技士',
        '２級ボイラー技士',
        '第一種電気工事士',
        '第二種電気工事士',
        'その他の電気工事関係資格',
        'ビルクリーニング技能士',
        'その他の免許資格',
    ],
}

const posOfferQualificationMappings = {
    その他: [
        '看護師',
        '衛生検査技師',
        '大型自動車免許',
        'その他の医療技術関係資格',
        '准看護師',
        '社会福祉士',
        '原動機付自転車免許',
        'その他の免許資格',
        'その他の医療・保健衛生関係資格',
    ],
    視覚訓練士: ['視能訓練士'],
}

// 成約実績の絞り込みの保有資格のマッピング

const kaigoRecordOfferQualificationMappings = {
    ヘルパー2級: ['介護職員初任者研修', 'カレッジ（初任者研修）'],
    ヘルパー1級: ['介護職員実務者研修'],
    介護支援専門員: ['ケアマネージャー'],
}

const eiyoushiOfferQualificationMappings = {
    '無資格（MM）': ['資格なし'],
}

export const offerQualificationMappings = site.isHoiku()
    ? hoikuOfferQualificationMappings
    : site.isKango()
    ? kangoOfferQualificationMappings
    : site.isKaigo()
    ? kaigoOfferQualificationMappings
    : site.isPos()
    ? posOfferQualificationMappings
    : site.isGishi()
    ? posOfferQualificationMappings
    : site.isEiyoushi()
    ? eiyoushiOfferQualificationMappings
    : site.isDental()
    ? null //現時点でデンタルのマッピング無し？
    : null

export const recordQualificationMappings = site.isKaigo
    ? kaigoRecordOfferQualificationMappings
    : null
