import site from 'shared/utils/site'
//施設形態マッピング
export const kangoServiceTypeMappings = {
    //【その他介護施設】0:その他
    0: [
        '101', //訪問介護
        '102', //夜間対応型訪問介護
        '105', //訪問リハ
        '107', //デイケア
        '108', //ショートステイ
        '110', //GH
        '112', //住宅型有老
        '114', //ケアハウス
        '116', //地域密着特養
        '117', //小多機
        '118', //看多機
        '121', //定期巡回
        '122', //居宅介護支援
        '123', //地域包括
        '124', //福祉用具
        '228', //養護老人ホーム
    ],
    //【介護医療院】120:介護医療院
    120: [
        '227', //病院（介護療養病床）
    ],
    //【その他障害施設】201:行動援護
    201: [
        '202', //重度障害者等包括支援
        '203', //ガイドヘルパー
        '204', //療養介護
        '205', //生活介護
        '206', //障害者ショートステイ
        '207', //障害者施設
        '208', //障害GH
        '209', //宿泊型自立訓練
        '210', //自立訓練（機能訓練）
        '211', //自立訓練（生活訓練）
        '212', //就労A
        '213', //就労B
        '214', //計画相談支援
        '215', //地域相談支援（地域移行支援）
        '216', //地域相談支援（地域定着支援）
        '217', //就労移行
        '218', //自立援助
        '219', //就労定着支援
        '220', //児童発達支援
        '221', //医療型児童発達支援
        '222', //放課後デイ
        '223', //居宅訪問型児童発達支援
        '224', //保育所等訪問支援
        '225', //障害児入所
        '226', //障害児相談支援
    ],
    //【保育園】401:認可保育園
    401: [
        '402', //小規模保育園
        '403', //認証・認定保育園
        '404', //院内・企業内保育園
        '405', //認可外保育園
    ],
    //【幼稚園】406:幼稚園-公立
    406: [
        '407', //幼稚園-私立
    ],
    //【こども園】408:認定こども園－地方裁量型
    408: [
        '409', //認定こども園－保育所型
        '410', //認定こども園－幼稚園型
        '411', //認定こども園－幼保連携型
    ],
}
export const posServiceTypeMappings = {
    //【有老】111:介護付き有老
    111: [
        '112', //112:住宅型有老
    ],
    //【就労継続支援】212:就労A
    212: [
        '213', //就労B
        '217', //就労移行
        '219', //就労定着支援
    ],
    //【保育園】401:認可保育園
    401: [
        '402', //小規模保育園
        '403', //認証・認定保育園
        '404', //院内・企業内保育園
        '405', //認可外保育園
    ],
    //【幼稚園】406:幼稚園-公立
    406: [
        '407', //幼稚園-私立
    ],
    //【こども園】408:認定こども園－地方裁量型
    408: [
        '409', //認定こども園－保育所型
        '410', //認定こども園－幼稚園型
        '411', //認定こども園－幼保連携型
    ],
}
export const eiyoushiServiceTypeMappings = {
    //【有老】111:介護付き有老
    111: [
        '112', //112:住宅型有老
    ],
    //【保育園】401:認可保育園
    401: [
        '402', //小規模保育園
        '403', //認証・認定保育園
        '404', //院内・企業内保育園
        '405', //認可外保育園
    ],
    //【幼稚園】406:幼稚園-公立
    406: [
        '407', //幼稚園-私立
    ],
    //【こども園】408:認定こども園－地方裁量型
    408: [
        '409', //認定こども園－保育所型
        '410', //認定こども園－幼稚園型
        '411', //認定こども園－幼保連携型
    ],
}

export const dentalServiceTypeMappings = {
    //【入所】106:デイ
    106: [
        '107', //デイケア
    ],
    //【通所】108:ショートステイ
    108: [
        '110', //GH
        '115', //特養
        '116', //地域密着特養
        '111', //介護付き有老
        '112', //住宅型有老
        '109', //老健
        '113', //サ高住
        '117', //小多機
        '118', //看多機
        '114', //ケアハウス
        '120', //介護医療院
        '119', //病院
    ],
}

export const commonServiceTypeMappings = site.isKango()
    ? kangoServiceTypeMappings
    : site.isPos()
    ? posServiceTypeMappings
    : site.isGishi()
    ? posServiceTypeMappings
    : site.isEiyoushi()
    ? eiyoushiServiceTypeMappings
    : site.isDental()
    ? dentalServiceTypeMappings
    : null
